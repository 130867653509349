import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import { Props } from "./types"
import { usePages } from "src/context/pages"
import Logo from "src/images/structured-data-logo.png"
import * as styles from "../global.module.scss"
import ToolsTeam from "src/components/ToolsTeams"
import ListTaskTeam from "src/components/ListTaskTeam"
import Reviews from "src/components/Reviews"
import CaseStudiesCards from "src/components/CaseStudiesCards"
import GetStarted from "src/components/Banners/GetStarted"
import HeaderTeamsPage from "src/components/HeaderTeamsPage"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { currentPath } = usePages()

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
    >
      <div className={styles.team}>
        <HeaderTeamsPage
          logosTitle={texts.logosTitle}
          title={texts.header}
          description={texts.description}
        />
        <ToolsTeam
          title={texts.tools.title}
          description={texts.tools.description}
          tools={texts.tools.list}
        />
        <ListTaskTeam {...texts.tasks} />
        <div className={styles.caseStudies}>
          <Reviews className={styles.reviews} subtitle={texts.reviewSubtitle} />
          <div className={`content-wrapper ${styles.caseStudiesCards}`}>
            <h2 className={styles.title}>{texts.casesTitle}</h2>
            <p className={styles.description}>{texts.caseDescription}</p>
            <CaseStudiesCards caseStudies={["dribbble", "aurea", "bench"]} />
          </div>
        </div>
        <div className={styles.banner}>
          <div className="content-wrapper">
            <GetStarted logosTitle={texts.logosTitleBanner} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        description
        logosTitle
        logosTitleBanner
        reviewSubtitle
        casesTitle
        caseDescription
        tools {
          title
          description
          list {
            title
            description
            icon
          }
        }
        tasks {
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bgImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageTitle
          title
          description
          list {
            title
            description
          }
          buttonText
        }
      }
    }
  }
`

export default Template
